.references-section {
    padding-top: 35px;
    padding-bottom: 0;

    .border-bottom {
        border-bottom: 1px solid #e5e5e5;
    }

    hr {
        &.references-separator {
            margin-top: 15px;
            // margin-bottom: 35px;
            margin-bottom: 15px;
            width: 60px;
        }
    }

    .section-subtitle {
        &.reference-title {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            text-align: left;
        }
    }

    hr {
        &.reference-separator {
            width: 60px;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .section-text {
        &.reference-text {
            clear: both;

            p {
                margin-bottom: 0;
            }

            a {
                font-weight: 400;
                text-transform: lowercase;
                border-bottom: 1px solid #CCC;
                padding-bottom: 2px;
                color: #777;

                &:hover {
                    text-decoration: none;
                    color: color('primary');
                    border-color: color('primary');
                }

                i {
                    margin-left: 5px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    .single-reference {
        padding: 35px 0;

        &:nth-of-type(even) {
            background: #faf7f5;
        }
    }
}

.SumoSelect.sumo_references_categories_filter {
    width: 200px;
    float: right;
    position: relative;
    top: 30px;
    margin: 0;
    z-index: 9;

    @media (max-width: 768px) {
        float: none;
        margin-bottom: 30px;
    }

    .CaptionCont > span {
        line-height: 32px;
    }

    .CaptionCont > label > i {
        line-height: 34px;
    }
}