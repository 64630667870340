.custom-link-list-block {
    background: #2d2f30;

    .row {
        > div {
            h3,
            a {
                font-weight: 500;
            }

            h3 {
                font-size: 16px;
                color: #FFF;
            }

            a {
                font-size: 13px;
                color: color('primary');
            }
        }
    }
}