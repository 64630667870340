.fw-container {
    position: fixed;
    top: 20%;
    width: auto;
}

.fw-toggler {
    position: absolute;
    top: 0;
    background: color('primary');
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    @include transition(all .3s ease-in);

    i {
        color: color('white');
        font-size: 22px;
        line-height: 22px;
    }
}

.fw-wrapper {
    display: inline-block;
    // position: fixed;
    top: 20%;
    padding: 0;
    height: auto;
    border: 1px solid color('primary');
    background: color('white');
    display: inline-block;
    width: 0;
    visibility: hidden;
    @include transition(all .3s ease-in);

    &.active {
        .fw-btn {
            visibility: visible;
            display: inline-block;
        }

        .fw-group-view-wrapper {
            visibility: visible;
            display: block;
        }

        .view-selector-container {
            visibility: visible;
            display: block
        }
    }

    .fw-btn {
        font-family: font(main);
        text-transform: uppercase;
        color: color('white');
        background: color('primary');
        @include transition(all .3s ease-in);
        border: 1px solid color('primary');
        font-weight: 700;
        margin-right: 10px;
        visibility: hidden;
        display: none;
        @include transition(all .3s ease-in);

        &.active {
            color: color(primary);
            background: transparent;
        }

        &:hover {
            background: darken(color('primary'), 20%);
        }
    }

    .fw-group-view-wrapper {

        padding: 10px 0;
        visibility: hidden;
        display: none;
        @include transition(all .3s ease-in);

        span {
            font-family: font(sub);
            font-size: 14px;
            color: color('text', 'dark');
            display: inline-block;
            margin-right: 5px;
        }
    }

    .view-selector-container {
        visibility: hidden;
        display: none;
        @include transition(all .3s ease-in);

        label {
            font-size: 14px;
            font-family: font(sub);
            color: color('text', 'dark');

            input {
                margin-left: 10px;
            }
        }
    }

}

#column-container {
    padding-top: 80px;
    padding-bottom: 80px;

    #column-right {
        .banner_item {
            width: 100% !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            margin-bottom: 10px;
           
        }

        .banner-content {
            width: calc(100% - 35px);
        }

        .documents-wrapper {


            .documents-text {
                display: none !important;
                visibility: hidden !important;
            }

            .col-md-4 {
                max-width: 100% !important;
                width: 100% !important;
                flex: 0 0 100% !important;

                .document-button {
                    display: block;
                    margin-bottom: 10px;

                }
            }

        }

        .category-tabs {
            margin-bottom: 5rem;
            margin-top: 5rem;

            .category-item {
                padding-bottom: 5px;
                display: block;
                margin-bottom: 5px;


            }
        }

        .cat-btn {
            display: block;
            text-decoration: none;
            text-transform: uppercase;
            font-family: font(main);
            font-weight: 700;
            color: color('text', 'darker');
            background: transparent;
            border: none;
            border-radius: 0;
            font-size: 1rem;
            padding: 0.8rem 2rem;
            @include transition(all .3s linear);

            &.active {
                background: color(primary);
                color: color('white');
            }
        }

        .all-btn {
            @extend .cat-btn;
        }
    }
}