.banners-wrapper {
    padding-top: 50px;

    &:last-child {
        padding-bottom: 50px;
    }

    @media(max-width: 768px) {
        padding-top: 0;
        padding-bottom: 0;
    }

    .banner-wrapper {
        display: flex;
        color: color('white');
        text-decoration: none;
        position: relative;

        @media(max-width: 768px) {
            margin-top: 50px;
        }

        a {
            width: 100%;
            display: block;
            height: 300px;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            .banner-content-wrapper {
                position: absolute;
                bottom: 0;
                padding: 30px;
                font-family: font(main);

                h2 {
                    font-size: 28px;
                    position: relative;
                    font-weight: 600;
                    text-transform: uppercase;

                    @media(max-width: 768px) {
                        font-size: 22px;
                    }
                }

                .separator {
                    height: 2px;
                    width: 100px;
                    margin: 10px 0;
                    background-color: color('terniary');
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.5;
                    text-decoration: none;
                }
            }
        }
    }
}