/* FONT IMPORTS */
// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800');
// @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700,800');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* FONTS */
$fonts: (
    Montserrat: ('Barlow', sans-serif),
    OpenSans: ('Barlow', sans-serif),
    main: ('Barlow', sans-serif),
    sub: ('Barlow', sans-serif),
);

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}