.contact-block-section {


    .btn-wrapper {
        text-align: center;
    }

    .contact-btn {

        background: color('primary');
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(sub);
        font-size: 16px;
        font-weight: 700;
        color: color('white');
        padding: 7px 15px;
        border: 2px solid color('primary');
        @include transition(all .3s linear);

        &:hover {
            color: color('text', 'darker');
            background: transparent;
        }
    }

}