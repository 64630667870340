.places-item {
    position: relative;

    a {
        display: flex;
    }

    span {
        &.places-title {
            position: absolute;
            z-index: 999;
            bottom: 10px;
            left: 30px;
            font-family: font('main');
            color: #FFF;
            font-size: 28px;
            text-shadow: 2px 2px 8px rgba(0, 0, 0, .9);
            line-height: 1.4;
            font-weight: 300;
        }
    }
}