.group-view-section {
    .list_component_wrapper {
        padding: 40px 0;

        .list-item {
            background-color: color('white');

            .list-link {
                &:hover {
                    text-decoration: none;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 40px;
            }

            @include box-shadow(1px 1px 50px 2px rgba(0, 0, 0, 0.3));

            .list-item-img-wrapper {
                background-color: color('white');
            }

            .list-item-header {
                margin-top: 10px;

                .list-item-title {
                    font-family: font(main);
                    color: color('text', 'dark');
                    font-weight: 500;
                    font-size: 18px;
                    text-transform: uppercase;
                }
            }



            .list-item-footer {
                border-top: none;
                background: transparent;

                .list-item-btn {
                    color: color('white');
                    border: 1px solid color('primary');
                    @include border-radius(none);
                    @include transition(all .3s linear);

                    &:hover {
                        background: transparent;
                        color: color('primary');
                    }
                }

            }
        }
    }
}