.section {
    &.group-view-section {
        padding-bottom: 0;

        .category_wrapper {
            .category-tabs {
                margin-bottom: 5rem;
                margin-top: 5rem;
                text-align: center;

                .category-item {
                    padding-bottom: 5px;

                    &:not(:last-of-type) {
                        border-right: 1px solid #cccccc;
                        padding-right: 5px;
                        padding-left: 5px;
                        margin-right: 0;
                    }

                    &:nth-of-type(1st) {
                        border: none !important;
                    }

                    @media(max-width: $media_sm) {
                        display: block;
                        margin-bottom: 5px;
                    }
                }
            }

            .cat-btn {
                text-decoration: none;
                text-transform: uppercase;
                font-family: font(main);
                font-weight: 700;
                color: color('text', 'darker');
                background: transparent;
                border: none;
                border-radius: 0;
                font-size: 1rem;
                padding: 0.8rem 2rem;
                @include transition(all .3s linear);

                &.active {
                    background: color('primary');
                    color: color('white');
                }
            }

            .all-btn {
                @extend .cat-btn;
            }
        }
    }
}