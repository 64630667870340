/*
    Slider se koristi za Template V3  unutar Slider Config-a u CMS-u
    Služi kao grid prikaz
*/
.section {
    &.slider_v3 {
        .slider_item {
            margin-bottom: 25px;

            &.slider-item-left {
                .slider-img {
                    float: left;
                    margin-right: 20px;
                }
            }

            &.slider-item-right {
                .slider-img {
                    float: right;
                    margin-left: 20px;
                }
            }

            .slider-img {
                margin: 0 auto;
                margin-bottom: 10px;
                display: block;
            }

            .item-content {
                .slider-item-link {
                    color: color('primary');

                    &:hover {
                        color: color('text', 'dark');
                    }
                }
            }
        }
    }
}