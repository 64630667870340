.banners_wrapper {
    .banner_item {
        position: relative;
        margin-top: 25px;

        @media(max-width: $media_md) {
            img {
                display: block;
                margin: 0 auto;
                width: 100%;
                margin-bottom: 15px;
            }


        }

        .banner-link {
            &:hover {
                text-decoration: none;
            }
        }

        .banner-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% - 30px);
            text-align: center;
            padding: 10px 0;
            background: rgba(color('background', 'dark'), 0.3);
            pointer-events: none;

            .banner-title {
                font-weight: 300;
                font-family: font(main);
                font-weight: 600;
                font-size: 16px;
                color: color('white');
                text-transform: uppercase;
            }
        }
    }

}

.group-view-section {
    h1 {
        text-align: center;
        font-size: 30px !important;
        font-weight: 300 !important;
        color: #555;
        text-transform: uppercase !important;
    }

    h2,
    h3 {
        text-align: center;
        font-weight: 300 !important;
        font-size: 30px !important;
        color: #555;
        text-align: center;
        font-style: italic;
    }

    .container-fluid {
        .banners_wrapper {
            img {
                width: 100%;
            }

            .banner-content {


                .banner-title {
                    font-size: 22px;
                }
            }
        }
    }
}