.section {
    &.detail-section {
        h1 {
            text-align: center;
            font-size: 30px;
            font-weight: 300 !important;
            color: #555;
            text-transform: uppercase !important;
        }
    
        h2,
        h3 {
            text-align: center;
            font-weight: 300 !important;
            font-size: 30px;
            color: #555;
            text-align: center;
            font-style: italic;
            text-transform: uppercase !important;
        }

        .detail-text {
            &.column-2 {
                -webkit-columns: 2;
                -moz-columns: 2;
                columns: 2;

                @media (max-width: $media_md) {
                    -webkit-columns: 1;
                    -moz-columns: 1;
                    columns: 1;
                    margin: 0 auto;
                }
            }
        }
    }
}