$bar_bg: rgba(255, 255, 255, 0.3);

.simplebar-track {
    .simplebar-scrollbar {
        &:before {
            background: $bar_bg;
        }
    }
}

// @import "~overlayscrollbars/css/OverlayScrollbars.min.css";